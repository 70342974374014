export var NO_PARENT_NODE = null;
export var UNCHECKED = 0;
export var INDETERMINATE = 1;
export var CHECKED = 2;
export var ALL_CHILDREN = 'ALL_CHILDREN';
export var ALL_DESCENDANTS = 'ALL_DESCENDANTS';
export var LEAF_CHILDREN = 'LEAF_CHILDREN';
export var LEAF_DESCENDANTS = 'LEAF_DESCENDANTS';
export var LOAD_ROOT_OPTIONS = 'LOAD_ROOT_OPTIONS';
export var LOAD_CHILDREN_OPTIONS = 'LOAD_CHILDREN_OPTIONS';
export var ASYNC_SEARCH = 'ASYNC_SEARCH';
export var ALL = 'ALL';
export var BRANCH_PRIORITY = 'BRANCH_PRIORITY';
export var LEAF_PRIORITY = 'LEAF_PRIORITY';
export var ALL_WITH_INDETERMINATE = 'ALL_WITH_INDETERMINATE';
export var ORDER_SELECTED = 'ORDER_SELECTED';
export var LEVEL = 'LEVEL';
export var INDEX = 'INDEX';
export var KEY_CODES = {
  BACKSPACE: 8,
  ENTER: 13,
  ESCAPE: 27,
  END: 35,
  HOME: 36,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  DELETE: 46
};
export var INPUT_DEBOUNCE_DELAY = process.env.NODE_ENV === 'testing' ? 10 : 200;
export var MIN_INPUT_WIDTH = 5;
export var MENU_BUFFER = 40;